<template>
  <table class="b2b-introduce">
    <thead class="b2b-introduce__header">
      <tr>
        <td colspan="4">
          <span>
            ※ 튜터링 전 과정은
            <strong>모바일 전용 과정</strong>
            으로, 앱 설치 후에 교육을 진행하실 수 있습니다. (앱설치필수) ※
          </span>
        </td>
      </tr>
    </thead>
    <tbody class="b2b-introduce__contents">
      <th class="b2b-introduce__contents__title">번호</th>
      <th class="b2b-introduce__contents__title">과정명</th>
      <th class="b2b-introduce__contents__title">설치주소</th>
      <th class="b2b-introduce__contents__title">QR코드</th>
      <tr v-for="(plan, idx) in plans" :key="idx">
        <td class="b2b-introduce__contents__number">
          {{ idx + 1 }}
        </td>
        <td class="b2b-introduce__contents__plan-name">
          {{ plan }}
        </td>
        <td class="b2b-introduce__contents__app-download" v-if="idx === 0" :rowspan="plans.length">
          <a :href="goToDownloadApp()" target="_blank">클릭</a>
        </td>
        <td class="b2b-introduce__contents__qr-code" v-if="idx === 0" :rowspan="plans.length"></td>
      </tr>
    </tbody>
    <tfoot class="b2b-introduce__footer">
      <tr>
        <td colspan="4">
          <span>
            ※ 튜터링 전 과정은
            <strong>모바일 전용 과정</strong>
            으로, 앱 설치 후에 교육을 진행하실 수 있습니다. (앱설치필수) ※
          </span>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
export default {
  name: 'B2BIntroduce',
  data() {
    return {
      plans: [
        '영어회화_Global tutor_월 6회',
        '영어회화_Global tutor_월 8회',
        '영어회화_Global tutor_월 10회',
        '영어회화_Global tutor_월 12회',
        '영어회화_Global tutor_월 20회',
        '영어회화_Native tutor_월 6회',
        '영어회화_Native tutor_월 8회',
        '영어회화_Native tutor_월 10회',
        '영어회화_Native tutor_월 12회',
        '영어회화_Native tutor_월 20회',
        '중국어회화_Chinese tutor_월 6회',
        '중국어회화_Chinese tutor_월 8회',
        '중국어회화_Chinese tutor_월 10회',
        '중국어회화_Chinese tutor_월 12회',
        '중국어회화_Chinese tutor_월 20회',
      ],
    };
  },
  computed: {
    userAgent() {
      return navigator.userAgent.toLowerCase();
    },
    isIos() {
      const regExpIos = /iphone|ipad|macintosh/i;
      if (regExpIos.test(this.userAgent)) {
        return true;
      }
      return false;
    },
    isAndroidOrWindows() {
      const regExpAndriodOrWindows = /android|windows/i;
      if (regExpAndriodOrWindows.test(this.userAgent)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    goToDownloadApp() {
      if (this.isIos) {
        return 'https://itunes.apple.com/kr/app/tutoring/id1115973003';
      }

      if (this.isAndroidOrWindows) {
        return 'https://play.google.com/store/apps/details?id=tutoring.app';
      }
    },
    setDeviceVh() {
      const deviceHeight = window.innerHeight;
      const b2bIntroducePage = document.getElementsByClassName('b2b-introduce');
      b2bIntroducePage[0].style.height = `${deviceHeight}px`;
    },
  },
  mounted() {
    this.setDeviceVh();
  },
};
</script>

<style lang="scss" scoped>
.b2b-introduce {
  width: 100vw;
  border: 1px solid black;
  font-size: 1rem;
  th,
  td {
    border: 1px solid black;
  }
}
.b2b-introduce__header,
.b2b-introduce__footer {
  background-color: #5c28d9;
  color: #ffffff;
  font-weight: normal;
  text-align: center;
  strong {
    color: #ffdf71;
  }
}
.b2b-introduce__contents {
  &__title {
    background-color: #04c09b;
  }
  &__number {
    text-align: center;
    font-weight: bold;
  }
  &__plan-name {
    padding: 5px 0px 5px 10px;
  }
  &__app-download {
    text-align: center;
    font-weight: bold;
    color: #5c28d9;
    text-decoration: underline 1px #5c28d9;
    cursor: pointer;
  }
  &__qr-code {
    background-image: url('https://res.tutoring.co.kr/res/images/b2b/QRCodeImg.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
}
</style>
