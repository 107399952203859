import { render } from "./Introduce.vue?vue&type=template&id=47769eca&scoped=true"
import script from "./Introduce.vue?vue&type=script&lang=js"
export * from "./Introduce.vue?vue&type=script&lang=js"

import "./Introduce.vue?vue&type=style&index=0&id=47769eca&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-47769eca"
/* hot reload */
if (module.hot) {
  script.__hmrId = "47769eca"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('47769eca', script)) {
    api.reload('47769eca', script)
  }
  
  module.hot.accept("./Introduce.vue?vue&type=template&id=47769eca&scoped=true", () => {
    api.rerender('47769eca', render)
  })

}

script.__file = "src/page/b2b/Introduce.vue"

export default script